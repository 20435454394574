import { takeEvery, takeLatest, all } from 'redux-saga/effects';

import systemSagas from '../common/system/SDNSystemSagas';
import userAdminSagas from '../views/SDNUserAdmin/SDNUserAdminSagas';
import { monitorChartSagas } from '../views/SDNMonitoring/SDNMonitorChart/SDNMonitorChartModel';
import { energySaga } from '../views/SDNMonitoring/SDNEnergy/SDNEnergyModel';
//能效管理
import { powerAnalysisSagas } from '../views/SDNPowerAdmin/SDNPowerAnalysis/SDNPowerAnalysisModel';
import { compareAnalysisSagas } from '../views/SDNPowerAdmin/SDNCompareAnalysis/SDNCompareAnalysisModel';
import { coulometryChargeSagas } from '../views/SDNPowerAdmin/SDNCoulometryChargeAnalysis/SDNCoulometryChargeModel';
import { SDNUnitConfigSagas } from '../views/SDNPowerAdmin/SDNUnitConfig/SDNUnitConfigModel';
import { loadAnalysisSagas } from '../views/SDNPowerAdmin/SDNLoadAnalysis/SDNLoadAnalysisModel';
import sysAdminSagas from '../views/SDNSysAdmin/SDNSysAdminSagas';
import eventAlertSagas from "../views/SDNEventAlert/SDNAlertSagas";

import equipmentSagas from '../views/SDNEquipment/SDNEquipmentSagas';
import baseConfigSagas from "../views/SDNBaseConfig/SDNBaseConfigSagas";
import activitiesSagas from "../common/activities/ActivitiesSagas";
import SDNOperationAdminSagas from '../views/SDNOperationAdmin/SDNOperationAdminSagas';
import SDNPowerOverviewSagas from '../views/SDNPowerAdmin/SDNPowerOverview/SDNPowerOverviewSagas';
import {demandAnalysisSagas} from "../views/SDNPowerAdmin/SDNDemandAnalysis/model";
import {peakAnalysisSagas} from "../views/SDNPowerAdmin/SDNPeakAnalysis/model";
import {electricQualitySagas} from "../views/SDNPowerAdmin/SDNElectricQualityAnalysis/model";
import {statementStatisticsSagas} from "../views/SDNPowerAdmin/SDNStatementStatistics/SDNStatementStatisticsModel";

import {dataInputSagas} from '../views/SDNPowerAdmin/SDNDataInput/model';
import {powerBillSagas} from "../views/SDNPowerAudit/SDNPowerBill/model";
import { SDNPowerRankSagas } from '../views/SDNPowerAdmin/SDNPowerRank/SDNPowerRankModel';
import { SDNPowerFlowSagas } from '../views/SDNPowerAdmin/SDNPowerFlow/SDNPowerFlowModel';
import { AirCtrlEfficiencyAnalysisSagas } from '../views/SDNPowerAdmin/SDNImportantAnalysis/AirCtrlEfficiencyAnalysisModel';
import {ledgerDetailSagas} from "../views/SDNEquipment/SDNLedgerDetail/model";
import {powerPeakAnalysisSagas} from "../views/SDNPowerAdmin/SDNPowerPeakAnalysis/model";
import {powerTimeShareAnalysisSagas} from "../views/SDNPowerAdmin/SDNTimeShareAnalysis/model";
import {powerCycleAnalysisSagas} from "../views/SDNPowerAdmin/SDNCycleAnalysis/model";

import {dataUploadSagas} from '../views/SDNDataUpload/model';
import {hnDataUploadSagas} from '../views/HunanDataUpload/model';
import {queryCurveSagas} from '../views/SDNGuangchuchong/Chunengcelve/Query/model';

function* rootSaga() {
  /*The saga is waiting for a action called LOAD_DASHBOARD to be activated */
  let sagas = [];
  sagas.push(...systemSagas);
  sagas.push(...userAdminSagas);
  sagas.push(...monitorChartSagas);
  sagas.push(...energySaga);
  //能效分析
  sagas.push(...powerAnalysisSagas);
  sagas.push(...compareAnalysisSagas);
  sagas.push(...coulometryChargeSagas);
  sagas.push(...demandAnalysisSagas);
  sagas.push(...powerBillSagas);
  sagas.push(...peakAnalysisSagas);
  sagas.push(...powerPeakAnalysisSagas);
  sagas.push(...powerTimeShareAnalysisSagas);
  sagas.push(...powerCycleAnalysisSagas);
  sagas.push(...electricQualitySagas);
  sagas.push(...statementStatisticsSagas);
  sagas.push(...loadAnalysisSagas);
  sagas.push(...SDNUnitConfigSagas);
  sagas.push(...SDNPowerRankSagas);
  sagas.push(...SDNPowerFlowSagas);
  sagas.push(...AirCtrlEfficiencyAnalysisSagas);

  sagas.push(...sysAdminSagas);
  sagas.push(...baseConfigSagas);
  sagas.push(...eventAlertSagas);
  sagas.push(...equipmentSagas);
  sagas.push(...ledgerDetailSagas);
  sagas.push(...activitiesSagas);

  sagas.push(...SDNOperationAdminSagas);
  sagas.push(...SDNPowerOverviewSagas);

  sagas.push(...dataInputSagas);
  
  sagas.push(...dataUploadSagas);
  sagas.push(...hnDataUploadSagas);
  sagas.push(...queryCurveSagas);
  

  yield all(sagas);
}

export default rootSaga;
